import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { EnvConfig } from '@bs24/core/models/environment-config';
import { IMenuItem } from '@bs24/core/models/menu';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

type MenuPositions = 'main' | 'side' | 'bottom';

@Injectable({ providedIn: 'root' })
export class MenuService {
  readonly url = `${this.config.api.endpoint}/cms/menus`;

  loadedSideMenu: Subject<IMenuItem> = new Subject<IMenuItem>();
  toggleItems: WritableSignal<Record<string, boolean>> = signal({ slip: false, sportMenu: false, chat: false });

  constructor(private http: HttpClient, private config: EnvConfig) {
  }

  sideMenu(id: number) {
    return this.http.get<IMenuItem>(`${this.url}/${id}`).pipe(tap(value => {
      this.loadedSideMenu.next(value);
    }));
  }

  loadMenu(id: number) {
    return this.http.get<IMenuItem>(`${this.url}/${id}`);
  }

  toggleMenu(expanded: boolean) {
    /*const menu = this.sectionMenu$.getValue();
    menu.expanded = expanded;
    this.sectionMenu$.next(menu);*/
  }

  toggleVisibility(item: 'sportMenu' | 'slip' | 'chat') {
    this.toggleItems.update(val => {
      val.menu = item === 'sportMenu' && !val.menu;
      val.slip = item === 'slip' && !val.slip;
      val.chat = item === 'chat' && !val.chat;
      return val;
    });
  }
}
